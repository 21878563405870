import React from 'react';

import Header from './Header';
import SEO from './SEO';
import Footer from './Footer';

const Layout = ({ description, keywords, title, children, footerImg }) => (
	<>
		<SEO description={description} keywords={keywords} title={title} />
		<Header />
		{children}
		<Footer footerImg={footerImg} />
	</>
);

export default Layout;
