import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Facebook from '../common/Facebook';
import Instagram from '../common/Instagram';
import Linkedin from '../common/Linkedin';
import { AddressDetails, SocialLink } from '../Footer';

const Footer = ({ footerImg }) => (
	<footer className="bg-white">
		<div className="container">
			<div className="row pt-5">
				<div className="col-6 col-md-3">
					<p className="font-weight-bolder mb-4">Rólunk</p>
					<p className="small text-secondary mb-3">
						<Link to="/courier" className="text-secondary text-underline">
							Futároknak
						</Link>
					</p>
					<p className="small text-secondary mb-3">
						<a
							className="text-secondary text-underline"
							href="http://blog.viddl.hu"
							target="_blank"
							rel="noopener noreferrer"
						>
							Blog
						</a>
					</p>
					<p className="small mb-3">
						<a href="/policy.pdf" className="text-secondary text-underline">
							Adatvédelmi nyilatkozat
						</a>
					</p>
					<div className="mb-3">
						<SocialLink
							href="https://www.facebook.com/viddlhungary"
							label="Facebook"
						>
							<Facebook />
						</SocialLink>
						<SocialLink
							href="https://www.linkedin.com/company/viddl"
							label="Linkedin"
						>
							<Linkedin />
						</SocialLink>
						<SocialLink
							href="https://www.instagram.com/viddl_"
							label="Instagram"
							isLast
						>
							<Instagram />
						</SocialLink>
					</div>
				</div>
				<div className="col-6 col-md-3">
					<AddressDetails
						title="Budapesti elérhetőségeink"
						address="1054 Budapest, Széchenyi utca 1."
						href="https://www.google.com/maps/place/ViddL/@47.5025502,19.0448318,18z/data=!4m13!1m7!3m6!1s0x4741dc16807cf8c5:0x6d8758ce461db39c!2sBudapest,+Széchenyi+u.+1,+1054!3b1!8m2!3d47.502543!4d19.0461649!3m4!1s0x4741ddbacf4151a9:0xef13a1e7bdf35bcb!8m2!3d47.5025574!4d19.0457574"
						mobile="+36 70 758 2536"
						email="budapest@viddl.hu"
						support="H-P 09:00-17:00"
					/>
					<AddressDetails
						title="Győri elérhetőségeink"
						address="9023 Győr, Bartók Béla út 1."
						href="https://www.google.hu/maps/place/Gy%C5%91r,+Bart%C3%B3k+B%C3%A9la+%C3%BAt+1,+9023/@47.6824949,17.63535,17z/data=!3m1!4b1!4m5!3m4!1s0x476bbfee3d00587f:0xea30c6b886098ee6!8m2!3d47.6824949!4d17.6375387"
						mobile="+36 30 110 63 38"
						email="gyor@viddl.hu"
						support="H-P 09:00-15:00"
					/>
					<AddressDetails
						title="Szegedi elérhetőségeink"
						address="6722 Szeged, Honvéd tér 5/b"
						href="https://www.google.hu/maps/place/Szeged,+Honv%C3%A9d+t%C3%A9r+5,+6722/@46.2484987,20.1410372"
						mobile="+36 20 458 16 50"
						email="szeged@viddl.hu"
						support="H-P 09:00-15:00"
					/>
				</div>
				<div className="col-6 col-md-3">
					<AddressDetails
						title="Debreceni elérhetőségeink"
						address="4029 Debrecen, Baross Gábor utca 16."
						href="https://www.google.com/maps/place/Debrecen,+Baross+Gábor+u.+16,+4029/@47.5292206,21.6351347,17z/data=!3m1!4b1!4m5!3m4!1s0x47470e77376d29b7:0xbd13311a064e89d0!8m2!3d47.5292206!4d21.6373234"
						mobile="+36 70 738 6262"
						email="debrecen@viddl.hu"
						support="H-P 09:00-15:00"
					/>
					<AddressDetails
						title="Kecskeméti elérhetőségeink"
						address="6000 Kecskemét, Külső-Szegedi út 51."
						href="https://www.google.com/maps/place/Kecskemét,+Külső+Szegedi+út+51,+6000/data=!4m2!3m1!1s0x4743da513c8cf59d:0x409764a3e60edb88?sa=X&ved=2ahUKEwjD6c22kMTvAhUvyoUKHX5VCrIQ8gEwAHoECAYQAQ"
						mobile="+36 20 280 93 81"
						email="kecskemet@viddl.hu"
						support="H-P 09:00-15:00"
					/>
					<AddressDetails
						title="Miskolci elérhetőségeink"
						address="3525 Miskolc, Szentpáli u. 9"
						href="https://www.google.hu/maps/place/Miskolc,+Szentp%C3%A1li+u.+9,+3525/@48.1040572,20.7864957"
						mobile="+36 70 328 0983"
						email="miskolc@viddl.hu"
						support="H-P 09:00-15:00"
					/>
				</div>
				<div className="col-6 col-md-3">
					<AddressDetails
						title="Székesfehérvári elérhetőségeink"
						address="8000 Székesfehérvár, Kamilla u. 8"
						href="https://www.google.hu/maps/place/Magyar+Posta+Logisztikai+Depo/@47.1896842,18.4787763,17z/data=!3m1!4b1!4m5!3m4!1s0x4769f96a679bc067:0xc793d3e83289f8b2!8m2!3d47.1896842!4d18.480965"
						mobile="+36 70 534 9507"
						email="szekesfehervar@viddl.hu"
						support="H-P 09:00-15:00"
					/>
					<AddressDetails
						title="Nyíregyházi elérhetőségeink"
						address="4420 Nyíregyháza, Tünde u. 10/a"
						href="https://www.google.hu/maps/place/Ny%C3%ADregyh%C3%A1zi+Logisztikai+%C3%9Czem/@47.9366682,21.730447,17z/data=!3m1!4b1!4m5!3m4!1s0x47389f5435917763:0xe09a8e3ae27925a!8m2!3d47.9366682!4d21.7326357"
						mobile="+36 70 758 2535"
						email="nyiregyhaza@viddl.hu"
						support="H-P 09:00-15:00"
					/>
				</div>
				<div className="col-12 text-right">
					<Link to="/" aria-label="ViddL">
						<Img fixed={footerImg.childImageSharp.fixed} />
					</Link>
				</div>
				<div className="col-12 pb-3">
					<p className="small">© {new Date().getFullYear()} Cargo-Viszed Kft</p>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
